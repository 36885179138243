import * as React from "react"
import {
    ChakraProvider,
    Box,
    Text,
    Link,
    VStack,
    Code,
    Grid,
    theme,
} from "@chakra-ui/react"
import { ColorModeSwitcher } from "./ColorModeSwitcher"
import { Logo } from "./Logo"
import { Button, ButtonGroup } from "@chakra-ui/react"
import styled from 'styled-components';
import Splash from './Splash';
import Login from './Login';
import CreateAccount from './CreateAccount';
import LocationSharing from './LocationSharing';
import FarTravel from './FarTravel';
import FullyVaxx from './FullyVaxx';
import CovidRequirements from './CovidRequirements';
import Interests from './Interests';
import Picked from './Picked';
import Results from './Results';
import Map from './Map';
import Details from './Details';
import Saved from './Saved';
import Italy from './Italy';
import Forms from './Forms';
import WhereTo from './WhereTo';

import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";


const Column = styled.div`
    text-align: center;
`

const Bar = styled.div`
    margin-top: 1vh;
    margin-bottom: 1vh;

`

const Rim = styled.div`
    border-style: solid;
    margin-top: 5vh;
`

const bstyle = {
    backgrounColor: '#4c4c4c',
}

export const App = () => (
    <ChakraProvider theme={theme}>
        <Router>
            <div>
                <Switch>
                    <Route path="/login">
                        <Login />
                    </Route>
                    <Route path="/createaccount">
                        <CreateAccount />
                    </Route>
                    <Route path="/locationsharing">
                        <LocationSharing />
                    </Route>
                    <Route path="/fartravel">
                        <FarTravel />
                    </Route>
                    <Route path="/fullyvaxx">
                        <FullyVaxx />
                    </Route>
                    <Route path="/covidrequirements">
                        <CovidRequirements />
                    </Route>
                    <Route path="/interests">
                        <Interests />
                    </Route>
                    <Route path="/picked">
                        <Picked />
                    </Route>
                    <Route path="/results">
                        <Results />
                    </Route>
                    <Route path="/map">
                        <Map />
                    </Route>
                    <Route path="/details">
                        <Details />
                    </Route>
                    <Route path="/saved">
                        <Saved />
                    </Route>
                    <Route path="/italy">
                        <Italy />
                    </Route>
                    <Route path="/forms">
                        <Forms />
                    </Route>
                    <Route path="/whereto">
                        <WhereTo />
                    </Route>
                    <Route path="/">
                        <Splash />
                    </Route>
                </Switch>
            </div>
        </Router>
    </ChakraProvider>
)

