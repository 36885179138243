import * as React from "react"
import {
    Box,
    Text,
    VStack,
    Code,
    Grid,
    theme,
} from "@chakra-ui/react"
import { ColorModeSwitcher } from "./ColorModeSwitcher"
import { Logo } from "./Logo"
import { Button, ButtonGroup, Heading } from "@chakra-ui/react"
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Column = styled.div`
    text-align: center;
`

const Bar = styled.div`
    margin-top: 1vh;
    margin-bottom: 1vh;

`

const Rim = styled.div`
    border-style: solid;
    margin-top: 5vh;
`

const bstyle = {
    backgrounColor: '#4c4c4c',
}

const FullyVaxx = () => (
    <Box textAlign="center" fontSize="xl">
        <Rim>
            <Grid minH="100vh" p={3}>
                <Column>
                    <Bar>
                        <Heading as="h2" size="lg">Are you fully vaccinated for Covid-19?</Heading>
                        <p>Sharing your vaccination status allows us to give you better travel recommendations.</p>

                    </Bar>
                    <Bar>
                        <Link to="/covidrequirements"><Button width="200px" colorScheme="gray">Yes</Button></Link>
                    </Bar>
                    <Bar>
                        <Link to="/covidrequirements"><Button width="200px" colorScheme="gray">No</Button></Link>
                    </Bar>
                </Column>
            </Grid>
        </Rim>
    </Box>
)

export default FullyVaxx;
