import * as React from "react"
import {
    Box,
    Text,
    VStack,
    Code,
    Grid,
    theme,
} from "@chakra-ui/react"
import { ColorModeSwitcher } from "./ColorModeSwitcher"
import { Logo } from "./Logo"
import { Button, ButtonGroup, Heading } from "@chakra-ui/react"
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Switch, Center } from "@chakra-ui/react"

const Column = styled.div`
    text-align: center;
`

const Bar = styled.div`
    margin-top: 1vh;
    margin-bottom: 1vh;
`

const Rim = styled.div`
    border-style: solid;
    margin-top: 5vh;
`

const bstyle = {
    backgrounColor: '#4c4c4c',
}

const TP = styled.span`
    margin-left: 1vw;
`

const Forms = () => (
    <Box fontSize="xl">
        <Rim>
            <Grid minH="100vh" p={3}>
                <Bar>
                    <Column>
                        <Heading as="h2" size="lg">Covid Forms</Heading>
                    </Column>
                    <Center>
                        <div>
                            <Bar>
                                <img src="cforms.png" alt="Covid Forms" />
                            </Bar>
                        </div>
                    </Center>
                    <Column>
                        <Link to="/picked"><Button width="200px" colorScheme="gray">Search</Button></Link>
                    </Column>
                </Bar>
            </Grid>
        </Rim>
    </Box>
)

export default Forms;
